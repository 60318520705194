@import "styles/_variables";

.Room {
    position: relative;
    height: 100%;
    &_controller {
        position: absolute;
        bottom: 40px;
        right: 50%;
        z-index: 1;
        transform: translateX(50%);
        display: flex;
        justify-content: space-between;
        width: 250px;
    }

    &_video {
        height: calc(100% - 66px);
        margin-top: 66px;
        background-color: $color-black;
        border-radius: 8px;
        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 8px;
        }

        &--side {
            video {
                position: absolute;
                bottom: 25px;
                right: 25px;
                width: 20%;
                object-fit: cover;
                border-radius: 8px;
                box-shadow: $box-shadow-light;
            }
        }
    }
}
