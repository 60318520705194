@import 'styles/_variables';

.Dialog {
    position: fixed;
    z-index: 1300;
    inset: 0;

    .backdrop-root {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        z-index: -1;
        position: fixed;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.5);
        -webkit-tap-highlight-color: transparent;
    }

    &-container {
        outline: 0;
    }

    &-scrollPaper {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
