@import "styles/_variables";

$drawer-width: 184px;

.Dashboard-layout {
    display: flex;

    &_AppBar {
        width: calc(100vw - 184px);
        margin-left: $drawer-width;
    }

    &_Drawer {
        width: $drawer-width;
        height: 100vh;
        position: fixed;

        &-white {
            height: 100%;
            background: $color-white;
        }

        &-logo {
            height: 132px;
            display: flex;
            justify-content: center;

            img {
                width: 102px;
            }
        }

        &-menu {
            display: flex;
            flex-direction: column;
            align-items: center;

            &-item {
                width: 146px;
                height: 50px;
                margin: 5px 0;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                svg {
                    & path {
                        fill: var(--color-primary);
                    }
                }

                img {
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    border: var(--color-primary) 1px solid;
                    //margin: auto;
                }
            }

            .-active {
                border-radius: 100px;
                background: var(--color-primary);

                svg {
                    & path {
                        fill: $color-white;
                    }
                }

                span {
                    color: $color-white;
                    margin-left: 10px;
                    font-weight: 500;
                    font-size: 13px;
                }
            }
        }

        &-logout {
            width: 100%;
            text-align: center;
            position: absolute;
            bottom: 40px;

            &-button {
                width: 44px;
                height: 44px;
                background: $color-gray;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50px;
                margin: auto;
                cursor: pointer;
            }
        }
    }

    &_Container {
        width: calc(100vw - 184px);
        margin-left: 184px;
    }

    &_Content {
        padding: 20px;
    }
}
