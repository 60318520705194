@import 'styles/_variables';

.NavBar {
    width: 100%;
    height: 100%;
    padding: 20px;

    &__Container {
        height: 100%;
        position: relative;
        background: linear-gradient(180deg, #8ABDA1 0%, #43BCB5 100%);
        box-shadow: 0 10px 20px rgba(61, 167, 210, 0.3);
        border-radius: 5px;

        &-logo {
            height: 132px;
            display: flex;
            justify-content: center;

            img {
                width: 97px;
            }
        }

        &-logout {
            width: 100%;
            text-align: center;
            position: absolute;
            bottom: 40px;

            &-button {
                width: 44px;
                height: 44px;
                background: rgba(255, 255, 255, 0.5);
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50px;
                margin: auto;
                cursor: pointer;

                svg {
                    & path {
                        fill: $color-white;
                    }
                }
            }
        }

        &-menu {
            display: flex;
            flex-direction: column;
            align-items: center;

            &-item {
                width: 80px;
                height: 50px;
                margin: 5px 0;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                svg {
                    & path {
                        fill: $color-white;
                    }
                }

                img {
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    border: $color-white 1px solid;
                }
            }

            .-active {
                border-radius: 100px;
                background: $color-white;

                svg {
                    & path {
                        fill: $color-green;
                    }
                }

                span {
                    color: $color-white;
                    margin-left: 10px;
                    font-weight: 500;
                    font-size: 13px;
                }
            }
        }
    }
}
