// stylelint-disable at-rule-no-unknown
// ------------------------------------- //
// mixins for media queries

@mixin screen-xlg {
    @media (max-width: $media-xlg) {
        @content;
    }
}

@mixin screen-lg {
    @media (max-width: $media-lg) {
        @content;
    }
}

@mixin screen-md {
    @media (max-width: $media-md) {
        @content;
    }
}

@mixin screen-sm {
    @media (max-width: $media-sm) {
        @content;
    }
}

@mixin screen-xs {
    @media (max-width: $media-xs) {
        @content;
    }
}

// ------------------------------------- //
// mixins for components

// @mixin inputGroupLabel {
//     display: flex;
//     flex-direction: column;
//
//     &-label {
//         font-size: 16px;
//         color: $color-darkgray;
//         margin-bottom: $spacing-half;
//
//         @include screen-md {
//             font-size: 14px;
//         }
//
//         @content;
//     }
// }
