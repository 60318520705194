:not(html, body)::-webkit-scrollbar {
    width: 7px;
}

:not(html, body)::-webkit-scrollbar-track {
    background: $color-lightgray;
    border-radius: 8px;
}

:not(html, body)::-webkit-scrollbar-thumb {
    background: var(--color-primary);
    border-radius: 8px;
}

:not(html, body)::-webkit-scrollbar-thumb:hover {
    background: $color-gray;
}

.Toastify {
    &__toast {
        &-body {
            white-space: pre-line;
        }
    }
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.flex-jsb {
    justify-content: space-between;
}

.flex-jsa {
    justify-content: space-around;
}

.flex-js {
    justify-content: flex-start;
}

.flex-je {
    justify-content: flex-end;
}

.flex-jc {
    justify-content: center;
}

.flex-as {
    align-items: flex-start;
}

.flex-ae {
    align-items: flex-end;
}

.flex-ac {
    align-items: center;
}

.flex-center {
    justify-content: center;
    align-items: center;
}

.flex-1 {
    flex: 1;
}

.flex-2 {
    flex: 2;
}

.flex-3 {
    flex: 3;
}

.flex-4 {
    flex: 4;
}

.flex-5 {
    flex: 5;
}

.flex-6 {
    flex: 6;
}

.gap-10 {
    gap: 10px;
}

.grid-container {
    display: flex;
    flex-wrap: wrap;
}

.grid-1 {
    width: 8.33%;
}

.grid-2 {
    width: 16.66%;
}

.grid-3 {
    width: 25%;
}

.grid-4 {
    width: 33.33%;
}

.grid-5 {
    width: 41.66%;
}

.grid-6 {
    width: 50%;
}

.grid-7 {
    width: 58.33%;
}

.grid-8 {
    width: 66.66%;
}

.grid-9 {
    width: 75%;
}

.grid-10 {
    width: 83.33%;
}

.grid-11 {
    width: 91.66%;
}

.grid-12 {
    width: 100%;
}

.grid-sm-6 {
    @include screen-sm {
        width: 50%;
    }
}

.grid-sm-12 {
    @include screen-sm {
        width: 100%;
    }
}

.m-auto {
    margin: auto;
}

.m-0 {
    margin: 0;
}

.m-10 {
    margin: 10px;
}

.mv-5 {
    margin-top: 5px;
    margin-bottom: 5px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-40 {
    margin-top: 40px;
}

.mt-50 {
    margin-top: 50px;
}

.mb-5 {
    margin-bottom: 5px !important;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mr-10 {
    margin-right: 10px !important;
}

.mr-20 {
    margin-right: 20px !important;
}

.p-0 {
    padding: 0 !important;
}

.mr-30 {
    margin-right: 30px !important;
}

.pr-5 {
    padding-right: 5px !important;
}

.pr-10 {
    padding-right: 10px !important;
}

.p-10 {
    padding: 10px;
}

.p-20 {
    padding: 20px;
}

.fw-800 {
    font-weight: 800;
}

.fw-600 {
    font-weight: 600;
}

.fw-500 {
    font-weight: 500;
}

.f-12 {
    font-size: 12px;
}

.pointer {
    cursor: pointer;
    user-select: none;
}

.d-flex {
    display: flex !important;
}

.align-items-center {
    align-items: center !important;
}

.centered {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.clickable {
    cursor: pointer;
}

.stop-scrolling {
    height: 100%;
    overflow: hidden;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-error {
    color: $color-red;
}

.bold {
    font-weight: bold;
}

.light {
    color: rgba($color: #000, $alpha: 0.6);
}
