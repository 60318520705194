@import 'styles/_variables';

.Popover {
    position: relative;
    display: inline-block;
    z-index: 1300;
    inset: 0;

    &-container {
        outline: 0;
        position: absolute;
        right: 0;
        transform: translate(0, 10px);
        background-color: $color-white;
        padding: 20px;
        border-radius: 10px;
        box-shadow: $box-shadow-light;
        width: auto;

        &::before {
            position: absolute;
            z-index: -1;
            content: '';
            right: 17px;
            top: -8px;
            border-style: solid;
            border-width: 0 10px 10px 10px;
            border-color: transparent transparent $color-white transparent;
            transition-duration: 0.3s;
            transition-property: transform;
        }
    }
}
