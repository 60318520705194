//-------------------------------------//
// MEDIA QUERIES
$media-xlg: 1600px;
$media-lg: 1200px;
$media-md: 992px;
$media-sm: 768px;
$media-xs: 480px;

//-------------------------------------//
// COLORS
// Projects color palette
// TODO_REPLACE(projects own color palette)
:root {
    --color-primary: #8abda1;
    --font-primary: "Lato", sans-serif;
}

$color-brand-blue: #0062ff;

$color-green: #8abda1;
$color-light-green: rgba(138, 189, 161, 0.7);
$color-soft-green: rgba(138, 189, 161, 0.1);
$color-green-2: #05f800;
$color-red-1: #f66f5e;
$color-syan: #74cdff;
$color-orange: #ffa869;
$color-red-hover: #e63939cc;
$color-soft-purple: #99a8dc;
$color-very-soft-pink: rgba(244, 0, 9, 0.2);
$color-soft-pink: #dc99b9;
$color-blue: #3aa6d3;
$color-soft-red: #f66f5e;
$color-mustard: #f3c566;

$color-lightgray-2: #eef0f2;
$color-gray: #e2e2ea;
$color-dark-gray: #92929d;
$color-yellow: #f3c566;
$color-red: #e63939;
$color-blue: #3aa6d3;
$color-gray-2: #cbcfd4;
$color-gray-3: #e0e0e0;
$color-lightgray-1: #dadada;
$color-red-2: #f40009;

$color-font: #a4a5bc;
$color-lightgray: #f8f8f8;
$color-lightgray-3: #f8f9fa;
$color-white: #fff;
$color-black: #000;
$color-shadow: #888;
$color-dark: rgba(0, 0, 0, 0.5);
$color-dark-2: #171725;
$color-body: #000;
$color-link: $color-body;
$color-dark-blue: #1e3d58;
$color-dark-gray-2: #1f314880;

$background-color-body: #f1f1f3;

//-------------------------------------//
// FONTS
// TODO_REPLACE(projects font families)
// $font-family-poppins: 'Poppins', Arial, Sans-Serif;
// $font-family-lato: 'Lato', Arial, Sans-Serif;

$font-family-body: "Raleway", sans-serif;
$font-color-body: $color-body;
$font-size-body: 14px;

$font-size-bold: 24px;
$font-size-small: 16px;
$font-size-very-small: 10px;

$font-family-lato: "Lato", sans-serif;

$font-light: 300;
$font-normal: 400;
$font-medium: 500;
$font-semi-bold: 600;
$font-bold: 700;
$font-bolder: 900;

//-------------------------------------//
// SPACING
$spacing: 20px;
$spacing-half: $spacing / 2;
$spacing-double: $spacing * 2;
$spacing-third: $spacing * 3;

//-------------------------------------//
// Z-INDEXING
$zIndex-AppModal: 1001;
// $zIndex-AppHeaderNav: 1000;
// $zIndex-AppSideNav: 1010;
// ...

//-------------------------------------//
// Global Variables

// Radius
// TODO_REPLACE(projects radiuses)
$border-radius-button: 4px;
$border-radius-small: 3px;
$border-radius-medium: 6px;
$border-radius-large: 9px;

// Box Shadow
// TODO_REPLACE(projects shadows)
$box-shadow-light: 0 1px 3px rgba(#000, 0.12), 0 1px 2px rgba(#000, 0.24);
$box-shadow-medium: 0 3px 6px rgba(#000, 0.16), 0 3px 6px rgba(#000, 0.23);
$box-shadow-heavy: 0 10px 20px rgba(#000, 0.19), 0 6px 6px rgba(#000, 0.23);

//-------------------------------------//
// Component Variables
// TODO_REPLACE(project component styling)
// App Header Nav
// $AppHeaderNav-height: 60px;
// $AppHeaderNav-z-index: $zIndex-AppHeaderNav;

// App Side Nav
// $AppSideNav-width: 240px;
// $AppSideNav-z-index: 160ms;

// ActivityIndicator
// $ActivityIndicator-stroke: $color-brand-blue;

// CheckBox
// $CheckBox-border: 2px solid #E4F0FC;
// $CheckBox-color: #AE77EA;
// $CheckBox-checked-background-color: #AE77EA;

// RadioBox
// $RadioBox-border: 2px solid #E4F0FC;
// $RadioBox-hover-border: 7px solid #e9dcf7;
// $RadioBox-hover-background-color: rgba(#AE77EA, 0.5);
// $RadioBox-checked-border: 7px solid #e9dcf7;
// $RadioBox-checked-background-color: #AE77EA;

// ...
